import { FINANCES } from './financesActionTypes';

export function getFinancesIndicators() {
    return {
        type: FINANCES.GET_FINANCES_INDICATORS,
        payload: {
            client: 'default',
            request: {
                url: `admin/finances/artist-remuneration-summary/current/check?_=${new Date().getTime()}`,
                method: 'GET',
            },
        },
    };
}

export function getTotal() {
    return {
        type: FINANCES.GET_TOTAL,
        payload: {
            client: 'default',
            request: {
                url: `admin/finances/artist-remuneration-summary/last?_=${new Date().getTime()}`,
                method: 'GET',
            },
        },
    };
}

export function getPaymentHistory({ queryString }) {
    return {
        type: FINANCES.GET_PAYMENT_HISTORY,
        payload: {
            client: 'default',
            request: {
                url: `admin/finances/artist-remuneration-summary/list${!!queryString && queryString.length > 0 ? queryString : ''}`,
                method: 'GET',
            },
        },
    };
}

export function getPaymentHistoryInner(id, queryString) {
    return {
        type: FINANCES.GET_PAYMENT_HISTORY_INNER,
        payload: {
            client: 'default',
            request: {
                url: `/admin/finances/artist-remuneration-summary/${id}/artists${!!queryString && queryString.length > 0 ? queryString : ''}`,
                method: 'GET',
            },
        },
    };
}

export function getPaymentHistoryInnerTotal(id) {
    return {
        type: FINANCES.GET_PAYMENT_HISTORY_INNER_TOTAL,
        payload: {
            client: 'default',
            request: {
                url: `/admin/finances/artist-remuneration-summary/${id}`,
                method: 'GET',
            },
        },
    };
}

export function getFinances({ queryString }) {
    return {
        type: FINANCES.GET_FINANCES_TABLE,
        payload: {
            client: 'default',
            request: {
                url: `admin/finances/artist-remuneration-summary/last/artists${!!queryString && queryString.length > 0 ? queryString : ''}`,
                method: 'GET',
            },
        },
    };
}

export function setCalculating(ratio) {
    return {
        type: FINANCES.SET_CALCULATING,
        payload: {
            client: 'default',
            request: {
                url: `admin/finances/artist-remuneration-summary/list`,
                method: 'POST',
                data: { ratio }
            },
        },
    };
}

export function setAmount(id, amount) {
    return {
        type: FINANCES.SET_AMOUNT,
        payload: {
            client: 'default',
            request: {
                url: `admin/finances/artist-remuneration-summary/artists/${id}`,
                method: 'PUT',
                data: { amount },
                id
            },
        },
    };
}

export const calculationAction = (type) => ({
    type: FINANCES[`${type === 'payment' ? 'MAKE' : 'RESET'}_${type.toUpperCase()}`],
    payload: {
        client: 'default',
        request: {
            url: `admin/finances/artist-remuneration-summary/${type === 'payment' ? 'pay' : type === 'changes' ? 'reset' : type === 'calculation' ? 'last' : ''}`,
            method: type === 'calculation' ? 'DELETE' : 'POST'
        },
    }
})

/* export const closePlaylistModal = () => ({
    type: FINANCES.CLOSE_PLAYLIST_MODAL
}) */