import React, { useEffect, useMemo, useState } from 'react';
import { LoaderMUI, ButtonMUI, DialogMUI, InputMUI } from '../../shared';
import styles from './Finances.module.scss';
import additionalStyles from '../Audio/Audio.module.scss';
import { classList } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getFinances, getFinancesIndicators, getTotal, setCalculating } from './storage/financesActions';
import { ReactComponent as Clock } from '../../assets/icons/calculatingIcon.svg'
import { ReactComponent as Calendar } from '../../assets/icons/calendarIcon.svg'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

let interval;

const PaymentBlock = () => {
  const dispatch = useDispatch()
  const { indicators: { calculating, can_calculate, date, is_paid }, indicatorsLoading, total } = useSelector(({ finances }) => finances)
  const prePaymentStatement = useMemo(() => !can_calculate && !calculating && !is_paid, [can_calculate, calculating, is_paid]);
  const [modal, setModal] = useState(false)
  const [btnLoad, setBtnLoad] = useState(false)
  const [payoutRatio, setPayoutRatio] = useState(total?.ratio || '')

  const calculate = () => {
    setBtnLoad(true)
    dispatch(setCalculating(payoutRatio))
      .then(res => {
        setBtnLoad(false)
        if (res.type.includes('_SUCCESS')) {
          interval = setInterval(() => {
            dispatch(getFinancesIndicators())
              .then(indicatorRes => {
                if (indicatorRes.type.includes('_SUCCESS') && !indicatorRes.payload.data.calculating) {
                  Promise.allSettled([
                    dispatch(getFinances({})),
                    dispatch(getTotal())
                  ])
                }
              })
          }, 30000 /* 30 seconds */)
          setModal(false)
        }
      })
  }

  useEffect(() => {
    if (!calculating) {
      clearInterval(interval)
      interval = undefined
    }
  }, [calculating])

  useEffect(() => {
    setPayoutRatio('')
  }, [modal])

  if (prePaymentStatement) return null

  return (<>
    <div className={classList(styles.paymentBlock, { [styles.isCalculating]: calculating })}>
      {indicatorsLoading // LOADING
        ? <LoaderMUI linear className={styles.loader} />
        : calculating // CALCULATING
          ? <div className={styles.calculating}>
            <Clock />
            <div className={styles.paymentBlockInfo}>
              <p className={styles.smallHeading}>Calculation in progress...</p>
              <span>Please wait for the process to complete.<br />
                It will take some time.</span>
            </div>
          </div>
          : <>
            <div className={styles.paymentBlockInfo}>
              <p className={styles.smallHeading}>Make a new payment</p>
              <span>Set up payment to artists for listening for the previous calendar month</span>
            </div>
            {can_calculate
              ? <ButtonMUI
                size='small'
                className={styles.calculateBtn}
                children={'Calculate payouts'}
                onClick={() => setModal(true)}
              />
              : <div className={styles.availableTime}>
                <div>
                  <span>Available from</span>
                  <p>{date ? dayjs(date).format('DD MMM, YYYY') : '-'}</p>
                </div>
                <Calendar />
              </div>
            }
          </>
      }
      <DialogMUI
        open={modal}
        onClose={() => setModal(false)}
      >
        <div className={styles.paymentBlockModal}>
          <h1>Calculate payouts</h1>
          <span>Enter a ratio to calculate payouts to artists for their listenings for the previous calendar month.</span>
          <div className={additionalStyles.inputLabel}>
            <p className={additionalStyles.inputLabel__title}>Payout ratio</p>
            <div className={additionalStyles.inputLabel__wrapper}>
              <InputMUI
                type={'number'}
                placeholder={'0.00'}
                value={payoutRatio}
                onChange={({ target: { value } }) => setPayoutRatio(value)}
              />
            </div>
          </div>
          <span>You will be able to view and edit all payouts amounts until the payment is confirmed.</span>
          <hr />
          <div className={styles.buttons}>
            <ButtonMUI
              size='small'
              className={styles.calculateBtn}
              children={'Calculate'}
              onClick={calculate}
              disabled={!payoutRatio || +payoutRatio <= 0}
              loading={btnLoad}
            />
            <ButtonMUI
              size='small'
              color='primary'
              children={'Cancel'}
              onClick={() => setModal(false)}
            />
          </div>
        </div>
      </DialogMUI>
    </div>
    <hr />
  </>);
};

export default PaymentBlock;