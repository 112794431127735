import { put, takeEvery, all } from 'redux-saga/effects';
import { handleProfileSnacks, handleAuthLoaders } from '../features/Auth/authSaga';
import { handleAudioSnacks } from '../features/Audio/storage/audioSaga';
import { handleAdvertiserSnacks } from '../features/Advertising/storage/advertisingSaga';
import { handlePageContentSnacks } from '../features/PagesContent/storage/pagesContentSaga';
import { handleFinancesSnacks } from '../features/Finances/storage/financesSaga';

export default function* rootSaga() {
  yield all([handleAuthLoaders(), handleSnack(), handleProfileSnacks(), handleAudioSnacks(), handleAdvertiserSnacks(), handlePageContentSnacks(), handleFinancesSnacks()]);
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* openErrorSnack(e) {
  //console.log(e)
  yield put({
    type: 'ERROR_SNACK_OPEN',
    payload:
      typeof e === 'string'
        ? e
        : e?.error?.response?.data === null
          ? e?.error?.response?.statusText
          : e?.error?.response?.data?.non_field_errors
            ? e?.error?.response?.data?.non_field_errors[0]
            : e?.error?.response?.data
              ? e?.error?.response?.data?.detail
              : 'Something went wrong'
                ? e?.error?.data
                : 'Something went wrong'
  });
}

export function* openSuccessSnack(e) {
  yield put({ type: 'SUCCESS_SNACK_OPEN', payload: e });
}

export function* resetErrorSnack() {
  yield delay(100);
  yield put({ type: 'ERROR_SNACK_CLOSE' });
}

export function* resetSuccessSnack() {
  yield delay(100);
  yield put({ type: 'SUCCESS_SNACK_CLOSE' });
}

export function* handleSnack() {
  yield takeEvery('ERROR_SNACK_OPEN', resetErrorSnack);
  yield takeEvery('SUCCESS_SNACK_OPEN', resetSuccessSnack);
}

export function* enableLoader() {
  yield put({ type: 'LOADING', payload: true });
}

export function* disableLoader() {
  yield put({ type: 'LOADING', payload: false });
}

export function* enableButtonLoader() {
  yield put({ type: 'BUTTON_LOADING', payload: true });
}

export function* disableButtonLoader() {
  yield put({ type: 'BUTTON_LOADING', payload: false });
}
